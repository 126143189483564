import React from 'react'
import { Box, Heading, Markdown, Paragraph, Text, Anchor } from 'grommet'
import { Catalog } from 'grommet-icons'
import Layout from '../components/PageLayout'
import Slice from '../components/Slice'
import SEO from '../components/SEO'
import CatalogBanner from '../components/CatalogBanner'
import { CONTENT } from '../../content/books'

const BooksPage = () => (
  <Layout>
    <SEO
      title="We buy, sell and trade books and more"
      description="We sell new and used books, with a focus on local authors. We also showcase local art and wares."
      pathname="/books"
    />
    <Slice>
      <Heading level="1">Books</Heading>
      <CatalogBanner />
      <Markdown
        components={{
          p: {
            component: Paragraph,
            props: {
              size: 'large',
              alignSelf: 'stretch',
            },
          },
          strong: {
            component: Text,
            props: {
              size: 'xlarge',
              weight: 800,
              alignSelf: 'stretch',
            },
          },
          a: {
            component: Anchor,
          },
        }}
      >
        {CONTENT}
      </Markdown>
    </Slice>
  </Layout>
)

export default BooksPage
