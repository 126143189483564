import React from 'react'
import { Box } from 'grommet'
import { Catalog } from 'grommet-icons'

const CatalogBanner = () => [
  <Box
    direction="row"
    alignSelf="center"
    alignContent="center"
    overflow="hidden"
    margin={{
      top: '-1em',
      bottom: '1em',
    }}
  >
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
    <Catalog size="small" color="accent-4" alt="" />
  </Box>,
]

export default CatalogBanner
