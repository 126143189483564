
export const CONTENT = `

## We sell books<br /> and things that bookstores sell.

**Our specialty is brand-new books by local authors.** We also have a wide variety of used books: fiction, poetry, Southwest writers, and more.

We take books for trade or donation, and buy books directly from local authors. Bring used books any time we're open.

### Trade Policy

Books must be clean and in excellent condition. We want contemporary and  classic titles.  

We do not buy books for cash. 

Please bring no more than two bags or boxes at a time. 

We cannot take textbooks, encyclopedias, computer manuals or magazines. We cannot take books with tears, creases, stains, pet hair, chewed corners, library stamps, stickers, writing or highlighting. A neat name of the previous owner inside is okay. 

We prefer not to take mass market paperbacks (the small ones) unless they are in excellent condition without spine cracks. 

We offer $3 credit for trade paperbacks, $2 for hardbacks, $1 for kid books. 

It’s a used-for-used situation: Trade credit can only be spent on used books in the store. 

Trade credit does not apply to new books or other merchandise, or to special orders. 


**Teachers, faculty, adjuncts, bring ID and get 20% off any book in the store. We appreciate you!**

## We buy local!

**If you're a local author, we want to carry your books!** Contact [Steve](mailto:organicbooksellers+steve@gmail.com), or come to the store during buying hours, 10am to 12 noon.

**If you're a local artisan, bring us things that bookstores might sell!** We want to carry your gift cards, calendars, stickers, and other crafts. Talk to [Kelly](mailto:organicbooksellers+kelly@gmail.com), or drop a sample by the store any day.`
